<script setup lang="ts">
import { ref, computed, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import menus from "../../constants/menus";
import { isMobile, isTablet } from "../../composables/helper.ts";
import { useUiStore } from "@/stores/ui";
import { useMerchantStore } from "@/stores/merchant";

const innerMenuHeight = ref(0);
const route = useRoute();
const router = useRouter();
const selectedMenuId = ref(-1);
const selectModal = ref(false);
const { $api } = useNuxtApp();
const sidebarHidden = computed(() => useUiStore().sidebarHidden);
const isJoinShow = computed(() => useUiStore().isJoinShow);
const profile = computed(() => useMerchantStore().profile);
const { value } = await $api.config.getFlagFeature("activateOrderForm");
const dataBlock = [
  "Checkout",
  "Invoice Link",
  "Transaksi",
  "Promosi",
  "Post Purchase Offer",
  "Metode Pengiriman Checkout",
  "Metode Pembayaran Checkout",
  "Ads Checkout",
];

if (isMobile() || isTablet()) {
  useUiStore().set({ sidebarHidden: true });
} else {
  useUiStore().set({ sidebarHidden: false });
}

const pathPage = route.path.split("/");
const slugPage = pathPage[1];
const HEIGHT_INNER_MENU_ITEM = 52;

// set parent menu open or not for submenu
menus.forEach((menu) => {
  const menuSplit = menu.link.split("/");
  if (menuSplit[1] === slugPage) {
    if (menu.subMenus) {
      const filterMenu = menu.subMenus.filter((menu) => !menu?.isHidden);
      innerMenuHeight.value =
        filterMenu.length * HEIGHT_INNER_MENU_ITEM -
        (filterMenu.length > 1 ? 16 : 0);
    }
    selectedMenuId.value = menu.id;
  }
});

let newMenu = menus;

// handle ff activeorderform
if (!value) {
  newMenu = menus.filter((menu) => menu.label !== "Order Form");
}

const onToggleInnerMenu = (menu: Record<string, any>) => {
  if (innerMenuHeight.value > 0 && selectedMenuId.value === menu.id) {
    innerMenuHeight.value = 0;
    selectedMenuId.value = -1;
  } else {
    const filterMenu = menu.subMenus.filter((menu) => !menu?.isHidden);
    innerMenuHeight.value =
      filterMenu.length * HEIGHT_INNER_MENU_ITEM -
      (filterMenu.length > 1 ? 16 : 0);
    selectedMenuId.value = menu.id;
  }
};

const setActiveMenu = (link: string) => {
  if (route.path === "/") {
    return link === route.path;
  }
  return link.includes(route.path);
};

onBeforeMount(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  router.beforeEach((to, from, next) => {
    if (isMobile()) {
      useUiStore().set({ sidebarHidden: true });
    }
    next();
  });
});

const handleBeforeRedirect = (link: string) => {
  if (isMobile()) {
    useUiStore().set({ sidebarHidden: true });
  }
  router.push(link);
};

const handleMenuClick = (subMenu: Record<string, any>) => {
  if (
    dataBlock.includes(subMenu.label) &&
    profile.value.kyc_status !== "final"
  ) {
    selectModal.value = true;
  } else {
    handleBeforeRedirect(subMenu.link);
  }
};
</script>

<template>
  <ModalCheckout v-model:isShowModal="selectModal" />
  <div
    v-if="isMobile() && !sidebarHidden"
    class="fixed top-0 z-10 h-screen w-screen bg-black opacity-40 md:hidden"
    @click="useUiStore().set({ sidebarHidden: true })"
  />
  <aside
    class="fixed inset-0 top-[56px] z-20 h-full max-h-screen w-0 flex-none overflow-y-auto lg:block lg:h-auto lg:pt-0"
    :class="[
      sidebarHidden ? 'overflow-hidden md:w-[68px]' : 'w-4/6 md:w-[250px]',
      isJoinShow && profile.kyc_status === 'draft'
        ? 'top-[92px]'
        : 'top-[56px]',
    ]"
  >
    <nav
      class="transition-left fixed flex h-full max-h-screen flex-col justify-between overflow-y-auto bg-white px-3 py-4 text-[#374151] duration-300 ease-in-out md:h-[calc(100%-56px)]"
      :class="[
        sidebarHidden
          ? '-left-[68px] w-[68px] overflow-hidden md:left-0'
          : 'left-0 w-4/6 md:w-[250px]',
      ]"
    >
      <ul>
        <li
          v-for="menu in newMenu"
          :key="menu.id"
          class="cursor-pointer text-sm font-medium"
        >
          <NuxtLink
            class="mb-4 flex cursor-pointer items-center rounded fill-[#1C64F2] p-2 hover:bg-[#EBF5FF] hover:text-[#1C64F2]"
            :class="
              setActiveMenu(menu.link)
                ? 'rounded bg-[#EBF5FF] fill-[#1C64F2] text-[#1C64F2]'
                : ''
            "
            :to="menu.subMenus?.length > 0 ? '' : menu.link"
            @click="
              menu.subMenus?.length > 0
                ? onToggleInnerMenu(menu)
                : handleMenuClick(menu)
            "
          >
            <div class="w-9 shrink-0">
              <!-- eslint-disable vue/no-v-html -->
              <div v-html="menu.icon"></div>
              <!-- eslint-enable -->
            </div>
            <span
              class="flex-1 whitespace-nowrap transition-opacity delay-100 duration-300 ease-in-out"
              :class="sidebarHidden ? 'opacity-0' : 'opacity-100'"
              >{{ menu.label }}</span
            >
            <img
              v-if="menu.subMenus"
              src="/icons/chevron-down.svg"
              alt="chevron down"
              class="transition-all delay-100 duration-300 ease-in-out"
              :class="sidebarHidden ? 'opacity-0' : 'opacity-100'"
              :style="{
                transform: `rotate(${
                  menu.id === selectedMenuId && innerMenuHeight > 0 ? -180 : 0
                }deg)`,
              }"
            />
          </NuxtLink>

          <ul
            v-if="menu.subMenus"
            class="overflow-hidden transition-all duration-200 ease-in-out"
            :class="menu.id === selectedMenuId ? 'mb-4' : ''"
            :style="`${
              menu.id === selectedMenuId ? 'height: auto;' : 'height: 0px'
            }`"
          >
            <li
              v-for="(subMenu, index) in menu.subMenus"
              :key="subMenu.id"
              class="cursor-pointer hover:text-[#1C64F2]"
              :class="[menu.subMenus.length === index + 1 ? '' : 'mb-2']"
            >
              <div
                class="block rounded p-2 hover:bg-[#EBF5FF] hover:text-[#1C64F2]"
                :class="[
                  setActiveMenu(subMenu.link)
                    ? 'bg-[#EBF5FF] text-[#1C64F2]'
                    : '',
                  sidebarHidden ? 'text-center' : 'p-2 pl-9',
                ]"
                @click="handleMenuClick(subMenu)"
              >
                {{ sidebarHidden ? subMenu.short : subMenu.label }}
              </div>
            </li>
          </ul>
        </li>
        <div
          v-if="!sidebarHidden && profile.kyc_status === 'draft'"
          class="absolute bottom-8 left-1 right-1 mx-2 flex w-[90%] flex-col items-center rounded-t-xl bg-[#F9FAFB] p-3 text-sm text-[#9CA3AF]"
        >
          <div>
            <IconsCrossSelling />
          </div>
          <div>Tingkatkan penjualanmu,</div>
          <nuxt-link
            class="flex items-center justify-between"
            :to="
              !profile.email_verified_at
                ? '/email-verification'
                : '/completing/step-1'
            "
          >
            <span>join FLIK Checkout</span>
            <IconsAngleRight />
          </nuxt-link>
        </div>
      </ul>
    </nav>
  </aside>
</template>
