<script lang="ts">
// import { onMounted } from "vue";
import { initFlowbite } from "flowbite";
import { useNotification } from "@kyvg/vue3-notification";
import { useUiStore } from "@/stores/ui";
import { useMerchantStore } from "@/stores/merchant";
import type { ApiResponse } from "@/types/api"; // Assuming an ApiResponse type exists
const router = useRouter();
// initialize components based on data attribute selectors
// onMounted(() => {
//   initFlowbite();
// });
export default {
  setup() {
    const { $api: api } = useNuxtApp();
    const isJoinShow = computed(() => useUiStore().isJoinShow);
    const sidebarHidden = computed(() => useUiStore().sidebarHidden);
    const profile = computed(() => useMerchantStore().profile);
    const { notify: notif } = useNotification();

    const getAvailableBalance = async () => {
      const { data: result, status }: ApiResponse<{ balance: number }> =
        await api.report.getAvailableBalance();
      if (status === "success") {
        useUiStore().set({ walletAmount: result?.balance || 0 });
      } else {
        useUiStore().set({ walletAmount: 0 });
      }
    };
    const query = router.currentRoute.value.query;
    getAvailableBalance();
    return {
      isJoinShow,
      sidebarHidden,
      profile,
      query,
      notif,
      router,
    };
  },
  mounted() {
    initFlowbite();
    if (
      this.query.order_id &&
      this.query.ordno &&
      this.query.status === "SUCCEEDED"
    ) {
      this.notif({
        title: "Beli Kredit Berhasil",
        type: "success",
        text: "Beli Kredit Berhasil",
      });
      router.push({ query: {} });
    }
  },
};
</script>
<template>
  <div>
    <Notif />
    <Topbar />
    <div class="lg:flex">
      <Sidebar />
      <main
        class="w-full min-w-0 flex-auto bg-[#F9FAFB] p-4 pl-0 md:pl-[92px] lg:max-h-full lg:overflow-visible"
        :class="[
          isJoinShow && profile.kyc_status === 'draft'
            ? 'mt-[111px] md:mt-[92px]'
            : 'mt-[56px]',
          sidebarHidden ? 'lg:pl-[84px]' : 'lg:pl-[266px]',
        ]"
      >
        <slot />
      </main>
    </div>
  </div>
</template>
